// Import material theming functions
@import '@angular/material/theming';

$toolbar-breakpoint: 600px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$app-margin-gros: 20px;
$app-margin-moyen: 10px;
$app-margin-petit: 5px;